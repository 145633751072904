<template>
  <div class="col-md-12">
    <main-nav />
    <main-titlebar myclass="redBar" showback="true" icon="fa-solid fa-right-from-bracket"/>
    
    <div class="trip-view-row">
    
    <p>Weet u zeker dat u wilt afmelden? <br>
    
    
      U ontvangt geen berichten of herinneringen meer van TripTrader wanneer u afmeld.</p>
    </div>
    <div class="trip-view-row">
        <div class="trip-view-col">
    <button @click="handleLogout" value="logout" class="default-button">Meld af</button>
        </div>
      </div>

   
  </div>
</template>
<script>

export default {
  name: "Logout",

  methods: {
    handleLogout(user) {
      this.loading = true;
      this.$store.dispatch("auth/logout", user).then(
        () => {
          this.$router.push("/");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
  computed: {
 
  },
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,

  }
};
</script>